import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import PostList from '../components/postList'

const Category = props => {
  const { data, pageContext } = props
  const { edges: posts, totalCount } = data.allWordpressPost
  const { title: siteTitle } = data.site.siteMetadata
  const { name: category } = pageContext
  const title = `${totalCount} post${
    totalCount === 1 ? '' : 's'
  } in the “${category}” category`

  return (
    <>
      <Hero className="hero" />
      <Helmet title={`${category} | ${siteTitle}`} />
      <PostList posts={posts} title={title} />
    </>
  )
}

export default Category

export const pageQuery = graphql`
  query CategoryPage($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    allWordpressPost(
      filter: { categories: { elemMatch: { slug: { eq: $slug } } } }
    ) {
      totalCount
      edges {
        node {
          ...PostListFields
        }
      }
    }
  }
`

const Hero = styled.div`
  content: '';
  display: block;
  width: 100%;
  padding-top: 56.25%;
  background: red;
  @media (min-width: 1025px) {
    padding-top: 600px;
  }
`
